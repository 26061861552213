import React from 'react'
import { graphql } from 'gatsby'

// Components
import CaseTemplate from 'components/templates/CaseTemplate'

interface PageTemplateProps {
  data: {
    page: {
      path?: string
      title?: string
      seo: any
      // eslint-disable-next-line camelcase
      fields: GatsbyTypes.WpCaseEcd_Acffields
    }
    cases: {
      nodes: GatsbyTypes.WpCaseEcd[]
    }
  }
  location?: any
}

const PageTemplate: React.FC<PageTemplateProps> = ({ data }) => (
  <CaseTemplate data={data} type="pfz" />
)

export const pageQuery = graphql`
  query casePfzById($id: String!) {
    cases: allWpCasePfz(limit: 3, filter: { id: { ne: $id } }) {
      nodes {
        id
        title
        uri
        acfFields {
          thumbnail {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    transformOptions: { grayscale: true }
                    placeholder: NONE
                  )
                }
              }
            }
          }
        }
      }
    }

    page: wpCasePfz(id: { eq: $id }) {
      id
      title
      fields: acfFields {
        thumbnail {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE)
              }
            }
          }
          clientName
          clientQuote
        }

        detail {
          description
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`

export default PageTemplate
